import React, { useContext, useEffect, useState } from "react";
import { GlobalInfo } from "../App";
import axios from "axios";
import useAuth from "../hook/useAuth";
import { useParams } from "react-router-dom";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "../components/ui/Sheet";
import { Button } from "react-bootstrap";
import PriceDealTranslation from "./PriceDealTranslation";
import { Icon } from "lucide-react";
import { ClientContext } from './../hook/ClientContext';
const PricingTranslate = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { clientDetails } = useContext(ClientContext);
  const context = useContext(GlobalInfo);
  const [url, setUrl] = useState("");
  const [showFiles, setShowFiles] = useState(false);
  const [side, setSide] = useState("bottom");
  const [open, setOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const tokenData = useAuth();
  const [numPages, setNumPages] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const { student_id, document_category_id, document_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  const [alldocuments, setAlldocuments] = useState([]);

  const getAlldocuments = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/document/all/${student_id}/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAlldocuments(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDocumentDetails = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/documentData/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDocumentDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getOrderDetails = async () => {
    const response = await axios.get(
      `${context.apiEndPoint}admin/candidate/get-order-details/${document_id}/${student_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.length > 0) {
      setOrderDetails(response.data[0]);
    } else {
      setOrderDetails([]);
    }
  };
  const FetchDocuments = async (noLoading = false) => {
    if (!noLoading) {
      setLoading(true);
    }
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/document/${student_id}/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.length > 0) {
        setDocuments(response.data[0]);
        setUrl(response.data[0].filepath);
      } else {
        setDocuments([]);
        setUrl("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (!noLoading) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getDocumentDetails();
    FetchDocuments();
    getAlldocuments();
    getOrderDetails();
  }, [document_category_id]);
  return (
    <div>
      {url && numPages > 0 && orderDetails.net_amt > 0 && (
        <div className="px-2 lg:px-6 ">
          <div
            className="alert alert-info bg-info-500 text-light-600 border-info-600 px-24 py-11 mb-0 fw-semibold text-lg radius-8 d-flex align-items-center justify-content-between"
            role="alert"
          >
            <span className="block sm:inline">
              <div className="flex flex-row gap-2">
                <p>
                  Default amount :{" "}
                  <span className="font-bold pr-2">
                    € {clientDetails?.is_demo_translator == 1 ? orderDetails.net_amt*2 : orderDetails.net_amt}
                  </span>
                  {orderDetails.newly_adjusted_amount && (
                    <span>
                      Newly adjusted amount :{" "}
                      <span className="font-bold">
                        € {clientDetails?.is_demo_translator == 1 ? orderDetails.newly_adjusted_amount*2 : orderDetails.newly_adjusted_amount}{" "}
                      </span>
                    </span>
                  )}
                </p>
              </div>
            </span>
            <button variant="outline" onClick={() => setOpen(true)}>
              Open
            </button>
          </div>
        </div>
      )}
      <div>
        <Sheet key={side} open={open} onOpenChange={setOpen}>
          <SheetContent
            side={side}
            className="overflow-y-auto"
            style={{ width: "100%" }}
          >
            <SheetHeader>
              <SheetTitle className="text-2xl font-bold text-gray-700 pt-8 text-transform: uppercase">
                {documentDetails?.document_name}
              </SheetTitle>
              <SheetDescription className="text-1xl text-gray-500 text-transform: capitalize">
                {documentDetails?.document_description}
              </SheetDescription>
            </SheetHeader>

            <div className="">
              {url && (
                <PriceDealTranslation
                  open={open}
                  setOpen={setOpen}
                  document_id={document_id}
                  student_id={student_id}
                  numPages={numPages}
                  document_category_id={document_category_id}
                />
              )}
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
};

export default PricingTranslate;
